import React, { useState,useEffect} from 'react';
import { useNavigate,useLocation } from "react-router-dom";
import Spinner from '../components/Spinner';
import Warning from '../components/Warning';
import Success from '../components/Success';
import config from '../../config';
import { message,Input } from 'antd';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import bg from '../../../src/assets/svg/bg.png';
import axios from 'axios';
import { useAuth } from '../../hooks/AuthProvider';
import { UserOutlined,TeamOutlined,LockOutlined,MailOutlined,PhoneOutlined } from '@ant-design/icons';

function Login() {
  const navigate= {useNavigate}
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [spin, setSpin] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shop = params.get('shop');
    const code = params.get('code');

  


    if (shop) {
     handleLogin(shop)
    }
  }, []);

  const { loginAction,error } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpin(true);
    try {

      await loginAction({ username, password });
      // if (error){
      //   message.error({ content: "Invalid User Details", duration: 4 });
      // }
      // else{
      //   message.success({content:'Logged In Successfully',duration:4})
      // }

      setSpin(false)

    } catch (error) {
      setSpin(false);
      setFailed(true);
      setFailedMessage(error.message || "Login failed");
      message.error({ content: "Internal Error", duration: 4 });
    }
  };

  

  const googleAuthentication = (res) => {
    let formData = new FormData();
    formData.append('auth_token', res['credential']);
    setSpin(true);

    axios.post(`${config.apiUrl}google/`, formData)
      .then((res) => {
        setTimeout(() => {
          navigate('/user/leads');
          setSpin(false);
        }, 1000);
        localStorage.setItem('Userdata', JSON.stringify(res['data']));
      })
      .catch((err) => {
        console.log(err);
        setSpin(false);
      });
  };

   
      const handleLogin = (shop) => {
     // Replace with dynamic shop name or input
        const redirectUri = "https://jawebme.onrender.com/shopify/callback/"
        const clientId = "c2fd0307892fec63ee86a6b6872bcfe5"; // Your Shopify app client ID
        const scopes = "read_products,write_products,read_orders,write_orders,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders";
    
        // Construct the Shopify authorization URL
        const authUrl = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}`;
    
        // Redirect the user to the Shopify authorization URL
        window.location.href = authUrl;
      };

      const HandleLoginWithShopify =()=>{
        window.location.href = 'https://apps.shopify.com/jawebchatbot';
      }
    


    


  return (
    <div className="bg-[#F3E6FF] flex h-screen flex-1">
      <div className='md:block'>
        <img className='h-screen' alt='background' src={bg} />
      </div>

      <div className='flex flex-col items-center flex-1 p-6 mt-32'>
        {failed && <Warning message={failedMessage} />}

        <div>
          <img alt='jaweb-bg' className='h-[59.69px] w-[235.62px]' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/Jaweb color logo.png' />
        </div>

        <div>
          <span className='font-bold text-lg'>LOGIN</span>
        </div>

        <div className="mb-2 w-[50%]">
          <label htmlFor="phone" className="block text-sm font-semibold text-gray-800">
            Username or Email
          </label>
          <Input
          style={{width:'100%'}}
          placeholder="Username or Email" 
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          prefix={<UserOutlined />} 
          required
          />

  
        </div>

        <div className="mb-2 relative w-[50%]">
          <label htmlFor="password" className="block text-sm font-semibold text-gray-800">
            Password
          </label>

          <Input.Password
          style={{width:'100%'}}
          placeholder="Password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          prefix= {<LockOutlined/>}
          required
          />


          <div
            className="absolute inset-y-0 right-0 flex mt-6 pr-3 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeSlashIcon width={15} className='text-black' />
            ) : (
              <EyeIcon width={15} className='text-black' />
            )}
          </div>
        </div>
         
        <div className="mb-2 relative w-[50%]">
        <div className='flex items-start'>
         <p className="mt-1 text-xs font-light text-center text-gray-700">
            Forgot Password?{' '}
            <a className="font-medium text-purple-600 hover:underline" href="/auth/passwordReset">
              reset password
            </a>
          </p>
         </div>
        </div>
  


        <div className="mt-2">
          {spin ? (
            <Spinner />
          ) : (
            <button
              onClick={handleSubmit}
              className="w-full px-36 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-900 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
            >
              <span>LOGIN</span>
            </button>
          )}



          <div className="relative flex items-center justify-center w-full mt-6 border border-t text-[#A7A7A7} px-2">
            <div className="absolute mx-6 text-[#A7A7A7]">Or</div>
          </div>

          <p className="mt-2 text-xs font-light text-center text-gray-700">
            Don't have an account?{' '}
            <a className="font-medium text-purple-600 hover:underline" href="/auth/payment">
              Signup
            </a>
          </p>
        </div>
          
        <div className='flex items-center gap-4'>
        <button className=" border flex gap-2 bg-white border-slate-200 rounded mt-2">
          <span>
            <GoogleOAuthProvider clientId={config.clientId}>
              <GoogleLogin
                onSuccess={credentialResponse => {
                  googleAuthentication(credentialResponse);
                }}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </GoogleOAuthProvider>
          </span>
        </button>

        <button onClick={()=>HandleLoginWithShopify()}  className=" border flex gap-2 bg-white border-slate-200 rounded mt-2 p-2">
                <img className='w-4 h-4'  src='https://jawebstorage.us-iad-1.linodeobjects.com/images/shopify.svg'/>
                <span className='font-medium '>Sign in with Shopify</span>
        </button>
        </div>

      </div>
    </div>
  );
}

export default Login;
