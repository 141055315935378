// AddNewCardForm.js

import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const AddNewCardForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();

  const handleOk = async () => {
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error('Stripe token error:', error);
      return;
    }

    form.validateFields()
      .then(values => {
        form.resetFields();
        onCreate({ ...values, paymentMethodId: token.id });
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  return (
    <Modal
      visible={visible}
      title="Add a new card"
      okText="Add Card"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="cardHolderName"
          label="Card Holder Name"
          rules={[{ required: true, message: 'Please input the card holder name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Card Details">
          <CardElement />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewCardForm;
