import React, { useState, useEffect, useDeferredValue } from 'react';
import { Button, Modal } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import config from '../../config';
import { PdfIcon } from '../../assets/svg/icon';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import axios from 'axios'
// import { LoaderIcon } from '../../assets/svg/icon.js';
import "../../Pages/Css/Questionlist.css"
import { useTranslation } from 'react-i18next';
import { Spin } from "antd";
// import { FaPlus } from "react-icons/fa6";
// import { RiDeleteBin7Line } from "react-icons/ri";


const UploadedFile = ({ data, onDelete, setSelectedFile, setVisible, handleDownload, toggledFile, setToggledFile }) => {
  const isEyeSlash = toggledFile !== data.id;
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const botTrainingTranslations = t("botTraining")


  const handleView = (file) => {
    setSelectedFile(file);
    setVisible(true);
    setToggledFile(isEyeSlash ? data.id : null);
  };
  const handleDeleteClick = () => {
    setModalVisible(true);
  };

  const handleConfirmDelete = () => {
    onDelete(data.id);
    setModalVisible(false);
  };

  const handleCancelDelete = () => {
    setModalVisible(false);
  };
  return (
    <div className="upload-file-card cusMargin-15">
      <div>
        <div className="flex items-center gap-6 mob-res-gap">
          <div className="leftPdf-img">
            <PdfIcon width={25} style={{ color: '#7460ff' }} />
          </div>
          <div className="rightPdf-img">
            <div>
              <div className="filetest mb-3">
                <h6 className="file_name">{data?.name}</h6>
                <button onClick={handleDeleteClick} className="deleteIcon">
                  <DeleteOutlined width={25} className="text-indigo" />
                </button>
              </div>
              <div className="filetest">
                <span className="datespan">{data?.date}</span>
                <button className="viewIcon" onClick={() => handleView(data)}>
                  {isEyeSlash ? <FaRegEyeSlash /> : <FaRegEye />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={botTrainingTranslations.confirmDltBtn}
        visible={modalVisible}
        onOk={handleConfirmDelete}
        onCancel={handleCancelDelete}
        okText={botTrainingTranslations.yesBtn}
        cancelText={botTrainingTranslations.noBtn}
        bodyStyle={{ textAlign: 'start' }}
        okButtonProps={{ style: { background: 'rgb(127, 40, 248)', borderColor: 'rgb(127, 40, 248)' } }}
      >
        {/* <p>Are you sure you want to delete "{data?.name}"?</p> */}
        <p>{botTrainingTranslations.confirmDltBtn}</p>
      </Modal>
    </div>
  );
};


const FileList = ({ uploadedFiles, isScrapped, onDelete, isLinksFetched, updatedList, totalScrapedWebsite }) => {
  const [files, setFiles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [containerHeight, setContainerHeight] = useState('auto');
  const [activeButton, setActiveButton] = useState('pdf');
  const [toggledFile, setToggledFile] = useState(null);
  const [websitesUrl, setWebsitesUrl] = useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [fetchedLinksList, setFetchedLinksList] = useState('')
  const [deleteUrl, setDeleteUrl] = useState(null);
  const [deleteAllModalVisible, setDeleteAllModalVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [loadingStates, setLoadingStates] = useState({});

  const { t } = useTranslation();
  const botTrainingTranslations = t("botTraining")

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  useEffect(() => {
    setFetchedLinksList(isLinksFetched)
  }, [isLinksFetched])

  useEffect(() => {
    setFetchedLinksList(isLinksFetched)
  }, [isLinksFetched])

  useEffect(() => {

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      }
    };

    axios.get(`${config.apiUrl}scraped-websites-list/`, headers).then((res) => {
      setWebsitesUrl(res.data.data)
      totalScrapedWebsite((res.data.data).length)
    })

  }, [isScrapped])

  useEffect(() => {
    totalScrapedWebsite(websitesUrl.length)
  }, [websitesUrl])

  useEffect(() => {
    let username = localStorage.getItem('username');

    fetch(`${config.apiUrl}files/?user=${username}`)
      .then((response) => response.json())
      .then((data) => {
        const refineddata = data.map((item) => {
          let filename = item.name.split('/').pop();
          if (filename.includes('?')) {
            filename = filename.split('?')[0];
          }
          return { ...item, name: filename, url: item.name };
        });
        setFiles(refineddata);

        // Calculate container height dynamically based on number of files
        if (refineddata.length > 2) {
          setContainerHeight('250px');
        } else {
          setContainerHeight('auto');
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [uploadedFiles]); // Trigger fetch when uploadedFiles change

  const deleteFile = (fileId) => {
    fetch(`${config.apiUrl}delete/${fileId}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      })
      .catch((error) => console.error('Error deleting file:', error));
  };

  const handleCancel = () => {
    setVisible(false);
    setToggledFile(null); // Reset the toggled file state when the modal is closed
  };

  const handleDownload = (file) => {
    fetch(file.url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name; // specify the filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        handleCancel();
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };

  // const handleDeleteFetchedLinks = (url) => {
  //   // console.log('delete', fetchedLinksList, url)


  //   const updatedWebsiteLinks = fetchedLinksList.websiteLinks.filter(item => item.url !== url);
  //   const updatedData = {
  //     ...fetchedLinksList,
  //     websiteLinks: updatedWebsiteLinks
  //   };

  //   setFetchedLinksList(updatedData)
  //   updatedList(updatedData)

  // }

  //Delete by modal 
  // const handleDeleteFetchedLinks = (url) => {
  //   setDeleteUrl(url); // Set the link to delete
  // };

  // const handleConfirmDeleteLink = () => {
  //   // Perform delete operation based on deleteUrl
  //   const updatedWebsiteLinks = fetchedLinksList.websiteLinks.filter(item => item.url !== deleteUrl);
  //   const updatedData = {
  //     ...fetchedLinksList,
  //     websiteLinks: updatedWebsiteLinks
  //   };

  //   setFetchedLinksList(updatedData);
  //   updatedList(updatedData);
  //   setDeleteUrl(null);
  // };

  // const handleCancelDeleteLink = () => {
  //   setDeleteUrl(null); // Reset deleteUrl state
  // };

  //Delete by Spin

  const handleDeleteFetchedLinks = (url) => {
    // Set loading to true for the specific item
    setLoadingStates((prevState) => ({
      ...prevState,
      [url]: true,
    }));

    setDeleteUrl(url); // Set the link to delete

    // Simulate a short delay (e.g., for loading spinner effect) before deleting
    setTimeout(() => {
      // Perform delete operation based on deleteUrl
      const updatedWebsiteLinks = fetchedLinksList.websiteLinks.filter(item => item.url !== url);
      const updatedData = {
        ...fetchedLinksList,
        websiteLinks: updatedWebsiteLinks,
      };

      // Update the state
      setFetchedLinksList(updatedData);
      updatedList(updatedData);

      // Reset states
      setDeleteUrl(null);

      // Reset loading state for the specific item
      setLoadingStates((prevState) => ({
        ...prevState,
        [url]: false,
      }));
    }, 500);  // Adjust delay as needed
  };
  const handleDeleteAllLinks = () => {
    setDeleteAllModalVisible(true); // Show Delete All confirmation modal
  };

  const handleConfirmDeleteAllLinks = () => {
    const updatedData = {
      ...fetchedLinksList,
      websiteLinks: []
    };

    setFetchedLinksList(updatedData);
    updatedList(updatedData);
    setDeleteAllModalVisible(false);
  };

  const handleCancelDeleteAllLinks = () => {
    setDeleteAllModalVisible(false);
  };

  const handleWebsiteDelete = (url) => {

    setShowLoader(true)
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      },
      params: {
        'websites_url': url
      }
    };

    axios.delete(`${config.apiUrl}scrape-websites/`, headers)
      .then((res) => {

        console.log(res.data)
        setWebsitesUrl(res.data.data)
        setShowLoader(false)

      })
      .catch(error => {
        console.error('Error adding question:', error);
        setShowLoader(false)
      });

  }

  return (
    <>
      {fetchedLinksList.websiteLinks && fetchedLinksList.websiteLinks.length > 0 && (
        <div className='deleteAll_wrapper'>
          <div class="line-with-text">
            <hr />
            <span class="line-text">{botTrainingTranslations.includeLinks}</span>
          </div>
          <div className='text-end texticon '>
            {/* <button className='delete_all text-end' onClick={handleDeleteAllLinks}>
              <DeleteOutlined className='delete_allicon' />
              <h2 className='mb-0 dlt-clr-text' >Delete all</h2>

            </button> */}
          </div>
          <div className="heightWrapperList">
            {fetchedLinksList.websiteLinks.map((item, index) => (
              <div className='wrapuploadList' key={index}>
                <div className="list_wrapper">
                  <div className="flex cnt-alg">
                    <div className="list-item-div">
                      <a href={item.url}>{item.url}</a>
                    </div>
                    <span>{item.characters}</span>
                  </div>
                  <button
                    onClick={() => handleDeleteFetchedLinks(item.url)}
                    className="deleteIcon"
                    disabled={loadingStates[item.url]}
                  >
                    {loadingStates[item.url] ? <Spin size="small" /> : <DeleteOutlined width={25} className="text-indigo" />}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

      )}
{/* 
      <Modal
        title={botTrainingTranslations.confirmDltBtn}
        visible={deleteUrl !== null}
        onOk={handleConfirmDeleteLink}
        onCancel={handleCancelDeleteLink}
        okText={botTrainingTranslations.yesBtn}
        cancelText={botTrainingTranslations.noBtn}
        bodyStyle={{ textAlign: 'start' }}
        okButtonProps={{ style: { background: 'rgb(127, 40, 248)', borderColor: 'rgb(127, 40, 248)' } }}
      >
        <p>{botTrainingTranslations.confirmDltBtn}</p>
      </Modal> */}
      <Modal
        title={botTrainingTranslations.confirmDltAll}
        visible={deleteAllModalVisible}
        onOk={handleConfirmDeleteAllLinks}
        onCancel={handleCancelDeleteAllLinks}
        okText={botTrainingTranslations.yesBtn}
        cancelText={botTrainingTranslations.noBtn}
        bodyStyle={{ textAlign: 'start' }}
        okButtonProps={{ style: { background: 'rgb(127, 40, 248)', borderColor: 'rgb(127, 40, 248)' } }}
      >
        <p>{botTrainingTranslations.allLinksDltBtn}</p>
      </Modal>


      <div className="flex items-center customTabs mt-4">
        <button
          className={`leftTab ${activeButton === 'pdf' ? 'active' : ''}`}
          onClick={() => handleButtonClick('pdf')}
        >

          {botTrainingTranslations.pdf}
        </button>
        <button
          className={`rightTab ${activeButton === 'website' ? 'active' : ''}`}
          onClick={() => handleButtonClick('website')}
        >

          {botTrainingTranslations.links}
        </button>
      </div>


      {activeButton === 'pdf' && (
        <div style={{ minHeight: files.length > 2 ? '250px' : 'auto', height: containerHeight, overflowY: 'auto' }}>
          {files.length > 0 ? (files.map((item) => (
            <UploadedFile
              key={item.id}
              data={item}
              onDelete={deleteFile}
              setSelectedFile={setSelectedFile}
              setVisible={setVisible}
              handleDownload={handleDownload}
              toggledFile={toggledFile}
              setToggledFile={setToggledFile}
            />
          ))) : ''}

          <Modal
            title="File"
            visible={visible}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {botTrainingTranslations.closeBtn}
              </Button>,
            ]}
          >
            {selectedFile && (
              <div className='flex items-center justify-content-between'>
                <p className='mb-0'>{selectedFile.name}</p>
                <DownloadOutlined className='download-icon' onClick={() => handleDownload(selectedFile)} />
              </div>
            )}
          </Modal>
        </div>
      )}

      {activeButton === 'website' && (

        <div>

          {
            websitesUrl.length > 0 ? (websitesUrl.map((url, index) => (

              <>
                <div key={index} className='flex wrapLinks'>
                  <div className='textweb'>{url}</div>

                  {showLoader ? <Spin style={{ color: '#7600EB' }} /> :
                    <Button
                      danger
                      className="dlt-clr"
                      icon={<DeleteOutlined />}
                      size="small"
                      onClick={() => handleWebsiteDelete(url)}
                    />
                  }

                </div>

              </>

            ))) : ''
          }

        </div>
      )}
    </>
  );
};

export default FileList;  
