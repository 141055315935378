// import { Button } from "antd";
// import React, { useEffect } from "react"
// import { useTranslation } from "react-i18next"


// const LanguageSelector = () => {
  
//   const { i18n } = useTranslation();
//   const languages = [
//     { code: 'en', name: 'English' },
//     { code:'ar', name: 'Arabic' }
//   ]

//   const changeLanguage = (lng) => { 
//     i18n.changeLanguage(lng)
//   }

//   useEffect(() => {
//     console.log(i18n.dir());
//     document.body.dir = i18n.dir()
//   },[i18n,i18n.language])

//   return (
//     <div >
//       {languages.map(lang => {
//         return (
//           <Button key={lang.code} onClick={()=>changeLanguage(lang.code)}>{lang.name}</Button>
//         );
//       })}
//     </div>
//   )
// }

// export default LanguageSelector;

import { Button, Dropdown, Menu } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = ({setSelectedLang}) => {
  
  const { i18n } = useTranslation();
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' }
  ];

  const changeLanguage = (lng) => { 
    i18n.changeLanguage(lng);
    setSelectedLang(lng)
    sessionStorage.setItem('selectedLang',lng)
    console.log("lng", lng)
  };

  useEffect(() => {
    console.log(i18n.dir());
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const menu = (
    <Menu>
      {languages.map(lang => (
        <Menu.Item key={lang.code} onClick={() => changeLanguage(lang.code)}>
          {lang.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="wrapperPad">
      <Dropdown overlay={menu} placement="bottomLeft" className="customDropdown">
        <Button>Select Language</Button>
      </Dropdown>
    </div>
  );
}

export default LanguageSelector;
