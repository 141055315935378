import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { message, Spin } from 'antd';
import './Css/FileUpload.css'
export default function Websitescraper({ websiteScrapped, fetchedLinks, startCount }) {
  const [websiteURL, setWebsiteURL] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  // const [fetchedLinks, setFetchedLinks] = useState([])


  useEffect(()=>{
    startCount(showLoader)
  },[showLoader])


  const onClick = (e) => {
    e.stopPropagation();
    setShowLoader(true);

    let formData = new FormData();
    formData.append('website_url', websiteURL);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
        'Accept': 'application/json'
      },
      params: {

        websites_url: websiteURL

      }
    };

    if (websiteURL) {

      axios.get(`${config.apiUrl}scrape-websites/`, headers)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {


            if (res.data.data.status_code == 201) {
              message.warning({ content: res.data.data.message, duration: 2 });
            } else {

              const tempJson = {
                baseUrl: websiteURL,
                websiteLinks: res.data.data,
                totalCharacter: 0
              }

              setWebsiteURL('');
              fetchedLinks(tempJson)
              message.success({ content: 'Links fetched successfully', duration: 2 });
              websiteScrapped(true);
            }
          }
          else{
            message.success({ content: res.message, duration: 3 });
          }
        })
        .catch((error) => {
          console.log(error)
          // message.error({ content: 'Try again after some time', duration: 3 });
        })
        .finally(() => {
          setShowLoader(false);
        });
    } else {
      setShowLoader(false);
      message.warning({ content: 'Please enter a valid URL', duration: 2 });
    }
  };

  return (
    <div className='flex items-center justify-center mb-6 gap-2 cus-inp-wid'>
      <input
        className='border p-2.5 rounded-lg inp-wid'
        placeholder='Link address'
        value={websiteURL}
        onChange={(e) => setWebsiteURL(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
      <button
        className='border white-text-clr text-sm rounded-lg block p-2.5 bg-[#7F28F8]'
        onClick={(e) => { onClick(e); e.stopPropagation(); }}
        disabled={showLoader} // Optionally disable button while loading
      >
        {showLoader ?

          <div className='spin_gap'>
            <Spin className='spin_bg' />
            Fetch links
          </div>
          : 'Fetch links'}
      </button>
    </div>
  );
}
