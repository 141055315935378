import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import CustomLayout from '../../layouts/Customlayout';
import axios from 'axios';
import SettingOption from './Settingsoptions';

export default function Zapier() {
    const [spinWheel, setSpin] = useState(false);

    const handleZapierLogin = async () => {
        setSpin(true);
        try {
            const response = await axios.get('http://localhost:8000/o/auth-url/');
            const { authorization_url } = response.data;
            window.location.href = authorization_url;
        } catch (error) {
            console.error('Error fetching Zapier OAuth URL:', error);
        } finally {
            setSpin(false);
        }
    };

    return (
        <CustomLayout>
            <div className='flex flex-col md:flex-row gap-6'>
                <div className='md:w-1/6'>
                    <SettingOption />
                </div>
                <div className='flex-1'>
                    <div style={{ padding: '20px' }}>
                        <span style={{ fontSize: '17px', fontWeight: 'bold', paddingRight: '10px' }}>
                            Steps to Integrate Jaweb With Zapier
                        </span>
                        <span>Coming Soon</span>
                    </div>
                    {/* <div style={{ padding: '20px' }}>
                        <Spin spinning={spinWheel}>
                            <Button type="primary" onClick={handleZapierLogin}>
                                Login with Zapier
                            </Button>
                        </Spin>
                    </div> */}
                </div>
            </div>
        </CustomLayout>
    );
}
