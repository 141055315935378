
const config = {
    // Pro:

    apiUrl: 'https://jawebcrm.onrender.com/api/',

    // Local:

    // apiUrl: 'http://127.0.0.1:8000/api/',

    // Dev:
    // apiUrl:"https://jawebbackendtest.onrender.com",


    clientId: '660970705625-l3vva2ba4e4sot6v3pjoui16sje5hjhj.apps.googleusercontent.com',
    mapKey:'AIzaSyAxFqSrDHfOa3Fitprtj6mjyenQj7U_WAs',

    debugMode: true,
  };
  
  export default config;
