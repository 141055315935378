import React from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserOutlined, BellOutlined, CreditCardOutlined, BookOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const MenuItem = ({ icon, path, label }) => (
    <>
      {icon}
      <span>{label}</span>
      <Link to={path} />
    </>
  );

  
const SettingOption = ({ shopify }) => {
  const location = useLocation();
  const locationPath = location.pathname.split('/');
  const currentpath = locationPath[locationPath.length - 1];

  const { t } = useTranslation();
  const accSettingTranslations = t("accountSetting");

  const menuItems = [];

  // Get the values of 'invited' and 'shopify'
  const isInvited = localStorage.getItem('invited');
  const isShopify = shopify;

  // If 'invited' is true, show only specific items
  if (isInvited) {
    menuItems.push(
      {
        key: 'edit-profile',
        label: <MenuItem label={accSettingTranslations.edit} icon={<UserOutlined />} path="/user/edit-profile" />,
      },
      {
        key: 'notification',
        label: <MenuItem label={accSettingTranslations.notification} icon={<BellOutlined />} path="/user/notification" />,
      },
      {
        key: 'change-password',
        label: <MenuItem label={accSettingTranslations.changePass} icon={<LockOutlined />} path="/user/change-password" />,
      }
    );
  } 
  // If 'shopify' is true, show another set of items
  else if (isShopify) {
    menuItems.push(
      {
        key: 'edit-profile',
        label: <MenuItem label={accSettingTranslations.edit} icon={<UserOutlined />} path="/user/edit-profile" />,
      },
      {
        key: 'notification',
        label: <MenuItem label={accSettingTranslations.notification} icon={<BellOutlined />} path="/user/notification" />,
      }
    );
  } 
  // Default condition (when 'invited' is false and 'shopify' is false)
  else {
    menuItems.push(
      {
        key: 'edit-profile',
        label: <MenuItem label={accSettingTranslations.edit} icon={<UserOutlined />} path="/user/edit-profile" />,
      },
      {
        key: 'notification',
        label: <MenuItem label={accSettingTranslations.notification} icon={<BellOutlined />} path="/user/notification" />,
      },
      {
        key: 'cards',
        label: <MenuItem label={accSettingTranslations.billing} icon={<CreditCardOutlined />} path="/user/billing" />,
      },
      {
        key: 'Credit',
        label: <MenuItem label="Credit" icon={<BookOutlined />} path="/user/credit" />,
      },
      {
        key: 'change-password',
        label: <MenuItem label={accSettingTranslations.changePass} icon={<LockOutlined />} path="/user/change-password" />,
      }
    );
  }

  return (
    <Menu mode="inline" selectedKeys={[currentpath]} items={menuItems} />
  );
};

export default SettingOption;
