import React, { useState, useEffect } from 'react'
import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import FileUpload from './components/FIleUpload'
import { useNavigate } from 'react-router-dom'
import Websitescraper from './components/Websitescraper'
import FileList from './components/FileList'
import CustomLayout from '../layouts/Customlayout'
import { UploadIcon } from '../assets/svg/icon.js'
import axios from 'axios'
import config from '../config.js'
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next'

export default function Settings() {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [fileListKey, setFileListKey] = useState(0);
  const [image, setImage] = useState('https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png');
  const [userdataVar, setUserdata] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isScrapped, setIsScrapped] = useState(false)
  const [isLinksFetched, setIsLinksFetched] = useState('')
  const [acceptedLinks, setAcceptedLinks] = useState('')
  const [showLoader, setShowLoader] = useState(false);
  const [totalScrapedCharacters, setTotalScrapedCharacters] = useState(0)
  const [scrapedWebsiteCount, setScrapedWebsiteCount] = useState(0)

  const { t } = useTranslation();
  const botTrainingTranslations = t("botTraining")


  useEffect(() => {
    loadUserdata();
  }, []);
  useEffect(() => {
    if (dataLoaded) {
      loadUserdata()
      setDataLoaded(false)
    }
  }, [dataLoaded])

  const handleFetchedLinks = (data) => {
    setIsLinksFetched(data)

    const tempData = data.websiteLinks
    setTotalScrapedCharacters(tempData.reduce((total, page) => total + page.characters, 0))

  }


  const updateFileList = () => {
    setFileListKey(prevKey => prevKey + 1);
  };

  // const handleIsScrapped = (data) => {
  //   setIsScrapped(!isScrapped)
  // }

  const loadUserdata = async () => {
    try {
      let userdata = localStorage.getItem('Userdata');
      const userdataVarparsed = JSON.parse(userdata);

      const response = await fetch('https://jawebcrm.onrender.com/api/user/CheckUserAuth', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${userdataVarparsed?.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        navigate('/auth/login');
      }

      const data = await response.json();
      setUserdata(data?.username);
      setImage(`https://jawebcrm.onrender.com${data?.image}`);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdatedList = (data) => {

    const tempData = data.websiteLinks
    setTotalScrapedCharacters(tempData.reduce((total, page) => total + page.characters, 0))
    setIsLinksFetched(data)
  }

  const handleTrainChatbot = async () => {

    // limit depends on current plan
    if ((scrapedWebsiteCount) < 1) {

      if(isLinksFetched){

        // limit depends on current plan
        // if((isLinksFetched.websiteLinks).length <= 10){

          if(isLinksFetched.baseUrl){
            setShowLoader(true);
    
            const aa = isLinksFetched.websiteLinks
        
            let formData = new FormData();
            formData.append('website_url', isLinksFetched.baseUrl);
            formData.append('website_path', JSON.stringify(aa));
        
            const headers = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${JSON.parse(localStorage.getItem('Userdata')).token}`,
                'Accept': 'application/json'
              }
            };
        
            if (isLinksFetched.websiteLinks) {
              await axios.post(`${config.apiUrl}scrape-websites/`, formData, headers)
                .then((res) => {
        
                  message.success({ content: 'Chatbot trained successfully', duration: 2 });
                  setShowLoader(false);
                  const tempJson = {
                    baseUrl:'',
                    websiteLinks:[],
                    totalCharacter:0,
                  }
                  setIsLinksFetched(tempJson)
                  setTotalScrapedCharacters(0)
                  setIsScrapped(!isScrapped)
        
                })
                .catch((error) => {
                  console.log(error);
                  setShowLoader(false);
                  message.error({ content: `An error occurred while scraping the website! Try again after some time. ${error}`, duration: 2 });
        
                })
            } else {
              setShowLoader(false);
            }
          }else{
            message.error({ content: `Fetch links first to train bot`, duration: 2 });

          }
        // }
        // else{
        //   message.error({ content: `You can only scrap upto 10 links`, duration: 2 });
        // }
      }else{
        message.error({ content: `Fetch links first to train bot`, duration: 2 });
      }

  
    }else{
      const tempJson = {
        baseUrl:'',
        websiteLinks:[],
        totalCharacter:0,
      }
      setIsLinksFetched(tempJson)
      setTotalScrapedCharacters(0)

      message.error({ content: `Website scraping limit exceed! Delete scraped website`, duration: 2 });
    }



  }

  const handleTotalScrapedWebsites= (data)=>{
    setScrapedWebsiteCount(data)
  }

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        setTotalScrapedCharacters(prevCount => prevCount + 1);
      }, 10); 
    } else {
      clearInterval(interval); 
    }
    
    return () => clearInterval(interval);
  }, [isActive]);
  

  const handleStartCount = (data) => {
    setIsActive(data)
  }


  return (
    <CustomLayout >

      <div className="customLayout-pad" >

      <div className="w-full flex flex-col items-center justify-center text-center mb-8 mt-10 ">
        <h2 className="bg-sethead">
            {botTrainingTranslations.heading} <br></br><span className="inner-sethead">{ botTrainingTranslations.subHeading}</span>
        </h2>
      </div>
      <div className="newWrapper">
        <div className="flex flex-col  newWrapper_main">

          <div className='Upload_wrapper' >
            <div className='flex items-center gap-3 mb-5 side-pad cus-wrapper-bb pd-10'>
              <UploadIcon />
              <div>
                  <h2 className='mb-2 upload_text'>{ botTrainingTranslations.uploadFile}</h2>
                  <p className='mb-0 upload-para'>{ botTrainingTranslations.uploadFileNote}</p>
              </div>
            </div>

            <FileUpload setDataLoaded={setDataLoaded} onFileUpload={updateFileList} username={userdataVar} fetchedLinks={handleFetchedLinks} startCount={handleStartCount} className="padd-15" />
            <div className='scroll-files'>
              <FileList key={fileListKey} isScrapped={isScrapped} fetchedLinks={isLinksFetched} isLinksFetched={isLinksFetched} updatedList={handleUpdatedList} totalScrapedWebsite={handleTotalScrapedWebsites} className="padd-15" />
            </div>
          </div>
        </div>
        <div className='newWrapper_lower'>
          <div className='cusCreate'>
              <h2 className='head_cuscreate'>{ botTrainingTranslations.source}</h2>
              <p className='para_cuscreate'>{isLinksFetched ? (isLinksFetched.websiteLinks).length : 0} Links <span className='span_cuscreate_clr'>({totalScrapedCharacters.toLocaleString()} {botTrainingTranslations.chars })</span></p>
              <h2 className='head_cuscreate'>{ botTrainingTranslations.totalChars}</h2>
              <p className='para_cuscreate span_cuscreate'>{totalScrapedCharacters.toLocaleString()}/<span className='span_cuscreate_clr'>400,000 { botTrainingTranslations.limit}</span></p>
            <button className='createBot' onClick={handleTrainChatbot} disabled={showLoader}>

              {showLoader ?
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px'
                }}>
                  <Spin className='spin_bg'/>
                  {botTrainingTranslations.trainBot}
                </div>
                 : botTrainingTranslations.trainBot}
           

            </button>

          </div>
        </div>
      </div>
      </div>

    </CustomLayout>
  )
}
