// import React from 'react';
// import { Button, Checkbox, Form, Input } from 'antd';
// import { ArrowRightOutlined } from '@ant-design/icons';
// import bg from '../../../src/assets/svg/logo.png';


// export default function SignUpShopify() {
// //   const onFinish = (values) => {
// //     console.log('Success:', values);
// //   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

//   const onFinish = async (values) => {
//     const verificationCodeUser = generateRandomNumberString(4);
//     setError("");
//     setSpin(true);

//     let formData = new FormData();
//     formData.append('username', values.username);
//     formData.append('password', values.password);

//     formData.append('otp', verificationCodeUser);

//     formData.append("team_id", generateRandomNumberString(10));

//     try {
//       const response = await axios.post(`${config.apiUrl}user/validate`, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       // Storing each key-value pair in local storage individually
//       localStorage.setItem('username', username);
//       localStorage.setItem('password', password);
//       localStorage.setItem('company_name', company);
//       localStorage.setItem('email', email);
//       localStorage.setItem('otp', verificationCodeUser);
//       localStorage.setItem('team_id', generateRandomNumberString(10));
//       // if (selectedFile) {
//       //   const reader = new FileReader();
//       //   reader.readAsDataURL(selectedFile);
//       //   reader.onload = () => {
//       //     localStorage.setItem('image', reader.result);
//       //   };
//       // }

//       localStorage.setItem('signupFormData', JSON.stringify([...formData]));


//       message.success({ content: "Valid Information", duration: 4 });

//       setTimeout(async () => {
//         try {
//           const response = await fetch(`${config.apiUrl}payment/create-checkout/?user-name=${username}`);
//           const data = await response.json();

//           if (!response.ok) {
//             throw new Error(data.message || 'Failed to create payment intent');
//           }

//           const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
//           if (result.error) {
//             throw new Error(result.error.message);
//           }
//         } catch (error) {
//           setError(error.message);
//         }

//         setSpin(false);
//       }, 3000);

//     } catch (error) {
//       if (error.response) {
//         message.error({ content: "Invalid Values", duration: 4 });

//         const { errors } = error.response.data;
//         if (errors) {
//           if (errors.username) {
//             setUsernameError(true);
//           }
//           if (errors.email) {
//             setEmailError(true);
//           }
//         }

//         setSpin(false);
//       } else if (error.request) {
//         console.error('No response received. Request:', error.request);
//       } else {
//         console.error('Error setting up the request:', error.message);
//       }
//     }
//   };


//   return (
//     <div className="flex flex-col justify-center items-center h-screen">
//       {/* Heading Section */}
//       <div className="mb-8 flex items-center">
   
//         <div className='p-2'>
//             <img className='w-6' src='https://jawebstorage.us-iad-1.linodeobjects.com/images/shopify.svg'/>
//         </div>
//         <div>
//             <ArrowRightOutlined />
//         </div>
//         <div className='p-2'>
//             <img src={bg} className='w-9 h-6 bg-[#7F28F8]'/>
//         </div>

//       </div>

//       <div className="mb-8">
//         <span className="text-lg font-semibold">Choose A Username and Password for your Account</span>
//       </div>
      
//       {/* Form Section */}
//       <div className="w-full max-w-md">
//         <Form
//           name="basic"
//           layout="vertical"
//           initialValues={{
//             remember: true,
//           }}
//           onFinish={onFinish}
//           onFinishFailed={onFinishFailed}
//           autoComplete="off"
//         >
//           <Form.Item
//             label="Username"
//             name="username"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input your username!',
//               },
//             ]}
//           >
//             <Input />
//           </Form.Item>

    //       <Form.Item
    //     name="password"
    //     label="Password"
    //     rules={[
    //       {
    //         required: true,
    //         message: 'Please input your password!',
    //       },
    //     ]}
    //     hasFeedback
    //   >
    //     <Input.Password />
    //   </Form.Item>

//       <Form.Item
//         name="confirm"
//         label="Confirm Password"
//         dependencies={['password']}
//         hasFeedback
//         rules={[
//           {
//             required: true,
//             message: 'Please confirm your password!',
//           },
//           ({ getFieldValue }) => ({
//             validator(_, value) {
//               if (!value || getFieldValue('password') === value) {
//                 return Promise.resolve();
//               }
//               return Promise.reject(new Error('The new password that you entered do not match!'));
//             },
//           }),
//         ]}
//       >
//         <Input.Password />
//       </Form.Item>

//           <Form.Item>
//             <Button type="primary" htmlType="submit">
//               Submit
//             </Button>
//           </Form.Item>
//         </Form>
//       </div>
//     </div>
//   );
// }



import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message,Spin } from 'antd';

const SignUpShopify = () => {
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const shop = params.get('shop');
      const code = params.get('code');
  
      if (shop && code) {
        // Send the code and shop to the Django backend to exchange it for an access token
        axios.post('https://jawebcrm.onrender.com/api/shopify/callback/', { shop, code })
          .then(response => {
            if (response.data.redirect_url) {
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('shop', response.data.shop);
              window.location.href = response.data.redirect_url;
            } else {
              // Store tokens in localStorage or sessionStorage
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('refreshToken', response.data.refreshToken);
              localStorage.setItem("Userdata", JSON.stringify(response.data));
  
              // Redirect to the logged-in view or dashboard
              navigate('/user/chatbot');
            }
          })
          .catch(error => {
            message.error('Authorization Error', 3);
            // Handle the error, maybe navigate back to login or show an error message
            navigate('/auth/login');
          });
      } else {
        // If no shop or code, redirect to login or show an error
        navigate('/auth/login');
      }
    }, [location, navigate]);
  
}  

export default SignUpShopify;
