import React, { useState, useEffect } from 'react';
import { Avatar, Button, Space, Table, Tag, Popconfirm, Dropdown, Menu, message } from 'antd';
import { SmallDashOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
// import config from '../../config';

const TableHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const position = "bottom";
  const align = "center";

  useEffect(() => {
    // Mock data for the table
    const fetchData = () => {
      const mockData = [
        {
          key: '1',
          posted: '2024-08-01',
          before: '$100.00',
          status: 'success',
        },
   
        {
          key: '2',
          posted: '2024-08-04',
          before: '$250.00',
          status: 'failed',
        },
       
      ];
      setData(mockData);
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'posted',
      key: 'posted',
      ellipsis: true,
    },
    {
      title: 'Amount',
      dataIndex: 'before',
      key: 'before',
      ellipsis: true,
    },
    {
      title: () => <div className="text-left">Status</div>,
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => (
        <div className="text-left">
          <Tag className="mr-0" color={record?.status === 'success' ? 'cyan' :  'volcano'}>
            {record?.status}
          </Tag>
        </div>
      ),
    },
  ];

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        {/* Additional controls can go here */}
      </Space>
      <Table
        pagination={{ position, align }}
        columns={columns}
        dataSource={data}
        loading={loading}
        // onChange={handleChange}
      />
    </>
  );
};

export default TableHistory;
