import React, { useState, useEffect } from 'react';
import { Row, Col, Menu, message as antdMessage } from 'antd';
import { CreditCardOutlined, UserOutlined, LockOutlined, BellOutlined ,BookOutlined } from '@ant-design/icons';
import { Link, useLocation as useReactRouterLocation } from 'react-router-dom';
import CustomLayout from '../../layouts/Customlayout';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import CreditCard from './components/CreditsCard';

const Credit = () => {
  const [creditAmount, setCreditAmount] = useState(1000.00); // Example credit amount
  const location = useReactRouterLocation();
  const stateMessage = location.state?.message;

  const { t } = useTranslation();
  const accSettingTranslations = t("accountSetting");

  useEffect(() => {
    if (stateMessage) {
      antdMessage.warning(stateMessage);
    }
  }, [stateMessage]);

 
  const MenuItem = ({ icon, path, label }) => (
    <>
      {icon}
      <span>{label}</span>
      <Link to={path} />
    </>
  );

  const SettingOption = () => {
    const locationPath = location.pathname.split('/');
    const currentPath = locationPath[locationPath.length - 1];

    return (
      <Menu
        mode="inline"
        selectedKeys={[currentPath]}
        items={[
          {
            key: 'edit-profile',
            label: <MenuItem label={accSettingTranslations.edit} icon={<UserOutlined />} path="/user/edit-profile" />,
          },
          {
            key: 'cards',
            label: <MenuItem label={accSettingTranslations.billing} icon={<CreditCardOutlined />} path="/user/billing" />,
          },
          {
            key: 'Credit',
            label: <MenuItem label={'Credit'} icon={<BookOutlined />} path="/user/credit" />,
          },
          {
            key: 'change-password',
            label: <MenuItem label={accSettingTranslations.changePass} icon={<LockOutlined />} path="/user/change-password" />,
          },
          {
            key: 'notification',
            label: <MenuItem label={accSettingTranslations.notification} icon={<BellOutlined />} path="/user/notification" />,
          },

        ]}
      />
    );
  };

  return (
    <CustomLayout>
      <div className='container'>
        <Row gutter={[16, 16]} className="flex-container">
          <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption />
          </Col>
          <Col xs={24} sm={24} md={16} className="content">
            <h2 className="mb-4 ml-4">Credits</h2>
            <div className='flex justify-center items-center'>
            <h1>Coming Soon!</h1>

            </div>
          </Col>
        </Row>
      </div>
    </CustomLayout>
  );
};

export default Credit;
