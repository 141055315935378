import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Integration from "../Pages/Integration";
import { notification } from "antd";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";


i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  returnObjects: true,
  lng:localStorage.getItem('i18nextLng') || 'en',
  resources:{
    en:{
      translation:{
        customization: {
          title:"Chat Interface",
          note: "Note : Applies when embedded on website",
          initialMsg: "Initial Messages",
          suggestedMsg: "Suggested Messages",
          chatbotProfile: "Update Chatbot profile picture",
          theme: "Theme",
          themeNote:"*If the changes here don't show up immediately on your website try clearing your browser cache or use incognito. (New users will see the changes immediately)*",
          chatbotIcon: "Update Chatbot Icon",
          disableForm: "Disable Chatbot Form",
          humanTakeover: "Human takeover (Global)",
          save: "Save",
          sendMsgPlaceholder: "Send Message...",
          
        },
        leads: {
          name:"Name",
          date: "Date / Time",
          country: "Country",
          phone: "Phone",
          action: "Action",
          exportExcel: "Export as Excel",
        },
        messages: {
          activeChats: "Active Chats",
          chats: "Chats",
          whatsapp: "Whatsapp",
          takecontrol: "Take Control",
          typePlaceholder: "Type Here and Press Enter",
          customerInfo: "Customer Info",
          contactNo: "Contact No",
          location: "Location",
          email: "Email",
          id: "Id",
          sendChatMsgPlaceholder: "Type Here And Press Enter",
          readMore: "... Read More",
          readLess:"Read Less",

        },
        integration: {
          website: "Website",
          wordpress: "Wordpress",
          whatsapp: "Whatsapp",
          zapier: "Zapier",
          introduction: "Introduction",
          introductionNote: "The cutting-edge chatbot plugin, is your company's gateway to seamless communication and enhanced customer engagement. Designed to revolutionize how businesses interact with their audience, Jaweb brings the power of artificial intelligence to your fingertips",
          introductionSubNote: "Follow the steps provided to install Jaweb plugin on your website . 😊 Enjoy!",
          supportedPlateforms: " Supported Platforms",

          websiteNote: "Note: This installation works for pure html files index.html",
          step1: "Follow the steps below to install Jaweb Plugin",
          step2_1: "First, create a ",
          step2_2:"file (choose any suitable name for your file)",
          step3: "In your body Tag Add the code below",
          step4: "Also add the code below in your Body tag as well",
          userIdentification: "User Identification",
          step5: "You can put this at the last line of the project",
          css: "CSS",
          step6: "This should be the last thing to put in your Head Tag",
          
          wordpressInstall: "Steps to Install on WordPress",
          followSteps: "Follow these steps to install on WordPress.",
          wstep1: "Click on the button below to download the plugin",
          wstep2: "Go to your WordPress admin page.",
          wstep3: "Go to the 'Plugins' section.",
          wstep4: "Click on 'Upload Plugin'.",
          wstep5: "Upload the downloaded ZIP file (plugin).",
          wstep6: "Activate the plugin.",
          wstep7: "Enter your username after activation.",
          downloadBtn:"Download",

          whatsappTab: {

            connectFbTab: "Connect to Facebook",
            connectBusinessTab: "Connect to Business",
            allSetTab:"All Set",

            integrationNote: "Follow the steps below to set up your Jaweb Account. Or, we have also sent you an email with a link so that you can start this process any time.",
            heading: "Create WhatsApp Business Account",
            subHeading1: "Your Business is required to create a WhatsApp Business Account in Facebook, or use your existing one if you have any.",
            subHeading2: "Facebook has a strict policy on reviewing your business information in the WhatsApp business account. Please make sure you have the information below prepared before proceeding.",
            note: "Note :",
            note1: "Your company’s legal name and address registered with local authorities.",
            note2: "Your WhatsApp Profile display name must match your company’s legal name.",
            note3: "A phone number that does not have an existing WhatsApp account.",
            fbLogin: "Login with Facebook",
            help:"Need Help?",
           
            createNote: "Hey there! You're almost there, Please click on the button below to connect your whatsapp business account with jaweb and register yourself as a Whatsapp message sender",
            cancelBtn: "Cancel",
            profileName: "Profile Name :",
            profileNamePlaceholder: "Enter Profile Name",
            phoneNumber: "Phone Number :",
            phoneNumberPlaceholder: "Enter Phone Number",

            allSet: "All Set than you!",
            successNote:"Your Facebook account has been connected with Jaweb. As it is a customer initiated conversation so your customers can contact you through Whatsapp. Once you receive any message from a customer then you'll be able to continue the conversation."

          }


        },
        accountSetting: {
          edit: "Edit Profile",
          billing: "Billing",
          changePass: "Change Password",
          notification: "Notifications",
          username: "Username",
          email: "Email",
          firstName: "First Name",
          lastName: "Last Name",
          region: "Region",
          language: "Language",
          saveChange:'Save Changes',
          
          billing: "Billing",
          cardType: "Card Type",
          cardNo: "Card Number",
          expire: "Expire on",
          addNew: "Add new Card",
          actions:"Actions",

          buycredit:"Buy Credit",

          changePass: "Change Password",
          newPass: "New Password",
          confirmPass: "Confirm Password",
          
          emailNoti: "Email Notifications",
          emailNotiNote: "You will receive daily email notifications.",
          newProductNoti: "New Product",
          newProductNotiNote: "You will receive notifications when a new product arrives.",
          chatNoti: "Chat",
          chatNotiNote:"You will receive notifications when someone assigns you a chat",

        },
        botTraining: {
          heading: "Tailor your bot's intelligence to perfection",
          subHeading: "by uploading PDF documents for specialized learning or a website link",
          uploadFile: "Upload Files",
          uploadFileNote: "Select and upload the files of your choice",
          fetchLinksBtn: "Fetch Links",
          pdf: "PDF",
          links: "Links",
          source: "Source",
          chars: "detected characters",
          totalChars: "Total detected characters",
          limit: "Limit",
          trainBot: "Train Chatbot",
          chooseFile: "Choose a file or drag & drop it here",
          urlLink: "or paste the URL below",
          
          includeLinks: "Included links",
          confirmDltBtn: "Confirm Delete",
          yesBtn: "Yes",
          noBtn: "No",

          confirmDltBtn: "Are you sure you want to delete this?",
          confirmDltAll:"Confirm Delete All",
          allLinksDltBtn: "Are you sure you want to delete all website links?",
          closeBtn:"Close"
          
        },
        navbar: {
          customization: "Customization",
          leads: "Leads",
          message: "Message",
          integration: "Integration",
          accSetting: "Account Setting",
          botTraining: "Bot Training",
          shopify:"Shopify",
          team:"Team",
          help:"Help",

          logout:"Logout",

        }
      }
    },
    ar:{
      translation: {
        customization: {
          title: "واجهة الدردشة",
          note: "ملحوظة: ينطبق ذلك عند تضمينه على موقع الويب",
          initialMsg: "الرسائل الأولية",
          suggestedMsg: "الرسائل المقترحة",
          chatbotProfile: "تحديث صورة الملف الشخصي للدردشة الآلية",
          theme: "سمة",
          themeNote: "*إذا لم تظهر التغييرات هنا على الفور على موقع الويب الخاص بك، فحاول مسح ذاكرة التخزين المؤقت للمتصفح أو استخدم وضع التصفح المتخفي. (سيتمكن المستخدمون الجدد من رؤية التغييرات على الفور)*",
          chatbotIcon: "تحديث أيقونة الدردشة الآلية",
          disableForm: "تعطيل نموذج الدردشة الآلية",
          humanTakeover: "الاستيلاء البشري (عالميًا)",
          save: "يحفظ",
          sendMsgPlaceholder: "إرسال رسالة...",
          

        },
        leads: {
          name:"اسم",
          date: "التاريخ / الوقت",
          country: "دولة",
          phone: "هاتف",
          action: "فعل",
          exportExcel: "تصدير بصيغة Excel",
          
        },
        messages: {
          activeChats: "الدردشات النشطة",
          chats: "الدردشات",
          whatsapp: "واتساب",
          takecontrol: "سيطر على الأمور",
          typePlaceholder: "اكتب هنا واضغط على Enter",
          customerInfo: "معلومات العميل",
          contactNo: "رقم الاتصال",
          location: "موقع",
          email: "بريد إلكتروني",
          id: "بطاقة تعريف",
          sendChatMsgPlaceholder: "اكتب هنا واضغط على Enter",
          readMore: "... اقرأ المزيد",
          readLess:"اقرأ أقل",

        },
        integration: {
          website: "موقع إلكتروني",
          wordpress: "ووردبريس",
          whatsapp: "واتساب",
          zapier: "زابير",
          introduction: "مقدمة",
          introductionNote: "يُعد برنامج الدردشة الآلي المتطور بمثابة بوابة شركتك للتواصل السلس وتعزيز مشاركة العملاء. صُمم برنامج Jaweb لإحداث ثورة في كيفية تفاعل الشركات مع جمهورها، حيث يضع قوة الذكاء الاصطناعي في متناول يديك",
          introductionNote: "اتبع الخطوات المقدمة لتثبيت البرنامج المساعد Jaweb على موقع الويب الخاص بك. 😊 استمتع!",
          supportedPlateforms: " المنصات المدعومة",

          websiteNote: "ملاحظة: يعمل هذا التثبيت على ملفات html النقية index.html",
          step1: "اتبع الخطوات التالية لتثبيت Jaweb Plugin",
          step2_1: "أولاً، قم بإنشاء",
          step2_2:"الملف (اختر أي اسم مناسب لملفك)",
          step3: "في جسمك أضف الكود أدناه",
          step4: 'أضف أيضًا الكود أدناه في علامة "الجسم" الخاصة بك أيضًا',
          userIdentification: "تحديد هوية المستخدم",
          step5: "يمكنك وضع هذا في السطر الأخير من المشروع",
          css: "سي اس اس",
          step6: "يجب أن يكون هذا هو آخر شيء تضعه في علامة الرأس الخاصة بك",
          
          wordpressInstall: "خطوات التثبيت على الووردبريس",
          followSteps: "اتبع الخطوات التالية للتثبيت على WordPress.",
          wstep1: "انقر على الزر أدناه لتنزيل البرنامج الإضافي",
          wstep2: "انتقل إلى صفحة إدارة WordPress الخاصة بك.",
          wstep3: 'انتقل إلى قسم "المكونات الإضافية".',
          wstep4: 'انقر فوق "تحميل البرنامج الإضافي".',
          wstep5: "قم بتحميل ملف ZIP الذي تم تنزيله (المكون الإضافي).",
          wstep6: "تفعيل البرنامج الإضافي.",
          wstep7: "أدخل اسم المستخدم الخاص بك بعد التنشيط.",
          downloadBtn:"تحميل",

          whatsappTab: {

            connectFbTab: "الاتصال بالفيسبوك",
            connectBusinessTab: "الاتصال بالعمل",
            allSetTab:"كل شيء جاهز",

            integrationNote: "اتبع الخطوات التالية لإعداد حساب Jaweb الخاص بك. أو أرسلنا لك أيضًا بريدًا إلكترونيًا يحتوي على رابط حتى تتمكن من بدء هذه العملية في أي وقت.",
            heading: "إنشاء حساب WhatsApp Business",
            subHeading1: "يجب على شركتك إنشاء حساب WhatsApp Business على Facebook، أو استخدام حسابك الحالي إذا كان لديك أي.",
            subHeading2: "لدى Facebook سياسة صارمة بشأن مراجعة معلومات عملك في حساب WhatsApp التجاري. يرجى التأكد من إعداد المعلومات أدناه قبل المتابعة.",
            note: "ملحوظة :",
            note1: "الاسم القانوني لشركتك وعنوانها مسجلين لدى السلطات المحلية.",
            note2: "يجب أن يتطابق اسم العرض الخاص بملفك الشخصي على WhatsApp مع الاسم القانوني لشركتك.",
            note3: "رقم هاتف لا يحتوي على حساب WhatsApp موجود.",
            fbLogin: "تسجيل الدخول باستخدام الفيسبوك",
            help:"هل تحتاج إلى مساعدة؟",
           
            createNote: "مرحبًا! لقد اقتربت من الوصول، يُرجى النقر على الزر أدناه لربط حساب الأعمال الخاص بك على WhatsApp بـjaweb وتسجيل نفسك كمرسل رسائل على WhatsApp",
            cancelBtn: "يلغي",
            profileName: "اسم الملف الشخصي :",
            profileNamePlaceholder: "أدخل اسم الملف الشخصي",
            phoneNumber: "رقم التليفون :",
            phoneNumberPlaceholder: "أدخل رقم الهاتف",

            allSet: "كل شيء على ما يرام منك!",
            successNote:"تم ربط حسابك على Facebook بـ Jaweb. نظرًا لأنه محادثة يتم بدءها من قبل العميل، فيمكن لعملائك التواصل معك عبر WhatsApp. بمجرد استلام أي رسالة من أحد العملاء، ستتمكن من متابعة المحادثة."

          }


        },
        accountSetting: {
          edit: "تعديل الملف الشخصي",
          billing: "الفواتير",
          changePass: "تغيير كلمة المرور",
          notification: "إشعار",
          username: "اسم المستخدم",
          email: "بريد إلكتروني",
          firstName: "الاسم الأول",
          lastName: "اسم العائلة",
          region: "منطقة",
          saveChange:'حفظ التغييرات',
          language: "لغة",
          
          billing: "الفواتير",
          cardType: "نوع البطاقة",
          cardNo: "رقم البطاقة",
          expire: "تنتهي صلاحيتها في",
          addNew: "إضافة بطاقة جديدة",
          actions:"الأفعال",
          buycredit:'"الأفعال',

          changePass: "تغيير كلمة المرور",
          newPass: "كلمة المرور الجديدة",
          confirmPass: "تأكيد كلمة المرور",
          
          emailNoti: "إشعارات البريد الإلكتروني",
          emailNotiNote: "سوف تتلقى إشعارات بالبريد الإلكتروني يوميًا.",
          newProductNoti: "منتج جديد",
          newProductNotiNote: "سوف تتلقى إشعارات عند وصول منتج جديد.",
          chatNoti: "الدردشات",
          chatNotiNote:"سوف تتلقى إشعارات عندما يقوم شخص ما بتعيين محادثة لك",

        },
        botTraining: {
          heading: "قم بتخصيص ذكاء الروبوت الخاص بك إلى الكمال",
          subHeading: "عن طريق تحميل مستندات PDF للتعلم المتخصص أو رابط موقع الويب",
          uploadFile: "تحميل الملفات",
          uploadFileNote: "حدد الملفات التي تريدها وقم بتحميلها",
          fetchLinksBtn: "جلب الروابط",
          pdf: "بي دي إف",
          links: "الروابط",
          source: "مصدر",
          chars: "تم اكتشاف الشخصيات",
          totalChars: "إجمالي الأحرف المكتشفة",
          limit: "حد",
          trainBot: "تدريب روبوت المحادثة",
          chooseFile: "اختر ملفًا أو اسحبه وأفلِته هنا",
          urlLink: "أو قم بلصق الرابط أدناه",
          
          includeLinks: "الروابط المضمنة",
          confirmDltBtn: "تأكيد الحذف",
          yesBtn: "نعم",
          noBtn: "لا",

          confirmDltBtn: "هل أنت متأكد أنك تريد حذف هذا؟",
          confirmDltAll:"تأكيد حذف الكل",
          allLinksDltBtn: "هل أنت متأكد أنك تريد حذف كافة روابط الموقع؟",
          closeBtn:"يغلق"
          

        },
        navbar: {
          customization: "التخصيص",
          leads: "العملاء المتوقعون",
          message: "رسالة",
          integration: "اندماج",
          accSetting: "إعداد الحساب",
          botTraining: "تدريب الروبوت",
          team:"فريق",
          help: "يساعد",
          logout:"تسجيل الخروج"
        }
      }
    },
  }
})