import React, { Component } from 'react';
import { Form, Button, Input, Row, Col, message, Menu } from 'antd';
import { UserOutlined, LockOutlined, BellOutlined, WechatWorkOutlined,	CreditCardOutlined,BookOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import CustomLayout from '../../layouts/Customlayout';
import './Css/AccountSettings.css';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import SettingOption from './components/MenuItems';



const ChangePasswordForm = ({ onFinish }) => {
  const { t } = useTranslation();
  const accSettingTranslations = t("accountSetting")


  return (
    
    <Form
      name="changePasswordForm"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label={accSettingTranslations.changePass}
        name="current_password"
        rules={[
          {
            required: true,
            message: 'Please enter your current password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={accSettingTranslations.newPass}
        name="new_password"
        rules={[
          {
            required: true,
            message: 'Please enter your new password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={accSettingTranslations.confirmPass}
        name="confirm_password"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Password not matched!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Button className='bg-[#7F28F8] text-white' htmlType="submit">
        {accSettingTranslations.changePass}
      </Button>
    </Form>
    
  )
};

class ChangePassword extends Component {



  changePasswordFormRef = React.createRef();



  onFinish = async (values) => {
    const key = 'updatable';
    message.loading({ content: 'Changing Password...', key });
    let userdata = localStorage.getItem('Userdata');
    const userdataVarparsed = JSON.parse(userdata);

    try {
      const response = await fetch(`${config.apiUrl}user/change-password/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userdataVarparsed?.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success({ content: 'Password Changed!', key, duration: 2 });
        this.onReset();
      } else {
        const data = await response.json();
        message.error({ content: data.error || 'Error changing password', key, duration: 2 });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  onReset = () => {
    this.changePasswordFormRef.current.resetFields();
  };

  

  render() {
    const UserInfo = JSON.parse(localStorage.getItem('UserObject'));
    const shopify = UserInfo?.mode === 'shopify';

    return (

      <CustomLayout>
        <div className="container">
          <Row gutter={[16, 16]} className="flex-container">
            <Col xs={24} sm={24} md={8} className="menu">
            <SettingOption shopify={shopify} />
            </Col>
            <Col xs={24} sm={24} md={16} className="content">
              <ChangePasswordForm onFinish={this.onFinish} />
            </Col>
          </Row>
        </div>
      </CustomLayout>
    );
  }
}

export default ChangePassword;
