import React, { useState, useEffect } from 'react';
import { Layout, Menu, Avatar, Drawer, Button, message } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import {
  AppstoreOutlined,
  MailOutlined,
  MessageOutlined,
  ToolOutlined,
  TeamOutlined,
  PhoneOutlined,
  UserOutlined,
  RobotOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  ShoppingOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WechatWorkOutlined,
  MenuOutlined // Added MenuOutlined for the toggler
} from '@ant-design/icons';

import arLogo from '../assets/svg/logo-ar.png';
import enLogo from '../assets/svg/logo.png';

const { Header, Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(JSON.parse(localStorage.getItem('sidebarCollapsed')) || false);
  const [iconNames, setIconNames] = useState([]);
  const [routePaths, setRoutePaths] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState(localStorage.getItem('s_m') || '1');
  const [selectedLang, setSelectedLang] = useState(sessionStorage.getItem('selectedLang') || 'en');
  const [isArabic, setIsArabic] = useState(selectedLang === 'ar');
  // let logout = false
  const [hoveredItem, setHoveredItem] = useState(null);
  const [logout, setLogout] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const navbarTranslation = t("navbar");
  const [visibleIcons, setVisibleIcons] = useState([])

  // Initialize iconNames and routePaths based on user role and permissions
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('UserObject'));
    const isSuperUser = localStorage.getItem("superUser") === "true";
  
    const newIconNames = [];
    const newRoutePaths = [];
    const newIconComponents = [];
  
    if (user) {
      if (user.team_permission) {
        const userRole = user.team_permission.team_permission;
        if (userRole === 'User') {
          newIconNames.push('leads', 'accSetting');
          newRoutePaths.push('/user/leads', '/user/edit-profile');
          newIconComponents.push(MailOutlined, UserOutlined);
        } else if (userRole === 'Integration') {
          newIconNames.push('customization', 'message', 'integration', 'botTraining', 'accSetting', 'help');
          newRoutePaths.push('/user/chatbot', '/user/messages/none', '/user/integration', '/user/settings', '/user/edit-profile', '/user/help');
          newIconComponents.push(WechatWorkOutlined, MessageOutlined, ToolOutlined, RobotOutlined, UserOutlined, QuestionCircleOutlined);
        } else if (userRole === 'Super User') {
          newIconNames.push('customization', 'leads', 'message', 'integration', 'botTraining', 'accSetting', 'help');
          newRoutePaths.push('/user/chatbot', '/user/leads', '/user/messages/none', '/user/integration', '/user/settings', '/user/edit-profile', '/user/help');
          newIconComponents.push(WechatWorkOutlined, MailOutlined, MessageOutlined, ToolOutlined, RobotOutlined, UserOutlined, QuestionCircleOutlined);
        }
      } else {
        newIconNames.push('customization', 'leads', 'message', 'integration', 'botTraining', 'team', 'accSetting', 'help');
        newRoutePaths.push('/user/chatbot', '/user/leads', '/user/messages/none', '/user/integration', '/user/settings', '/user/Adminusers', '/user/edit-profile', '/user/help');
        newIconComponents.push(WechatWorkOutlined, MailOutlined, MessageOutlined, ToolOutlined, RobotOutlined, TeamOutlined, UserOutlined, QuestionCircleOutlined);
      }
  
      if (user.mode === "shopify") {
        newIconNames.push('shopify');
        newRoutePaths.push('/user/shopify'); // Ensure there's a corresponding route
        newIconComponents.push(ShoppingOutlined); // Push the icon directly
      }
    }
  
    setIconNames(newIconNames);
    setRoutePaths(newRoutePaths);
    setVisibleIcons(newIconComponents); // No need to push arrays, just set the array directly
  }, []);
  
  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', collapsed);
  }, [collapsed]);

  const handleMenuClick = (e) => {
    if (!logout) {
      localStorage.setItem('s_m', e.key);
      setSelectedMenuItem(e.key);
    }
  };

  const handleMouseEnter = (key) => {
    setHoveredItem(key);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || 'en';
    setSelectedLang(lang);
  
  }, []);

  useEffect(() => {
    setIsArabic(selectedLang === 'ar');
    sessionStorage.setItem('selectedLang', selectedLang);
  }, [selectedLang]);


  const handleLogout = () => {
    setLogout(true);
    localStorage.clear();
    message.error({ content: 'Successfully Logged out', duration: 4 });
    setTimeout(() => {
      navigate('/auth/login');
    }, 3000);
  };

  const shouldRenderHeader = () => {
    return !location.pathname.includes('/user/messages/');
  };
  
  
  const items = iconNames.map((name, index) => {
    const IconComponent = visibleIcons[index] || UserOutlined; // Access the 1D array
  
    if (!IconComponent) {
      console.warn(`Icon component for ${name} is undefined`);
      return null; // Skip rendering this item if the component is undefined
    }
  
    console.log(`Rendering icon for ${name}:`, IconComponent);
  
    return {
      key: String(index + 1),
      icon: React.createElement(IconComponent),
      label: t(`navbar.${name}`),
      path: routePaths[index],
    };
  });
  return (
    <Layout style={{ minHeight: '100vh' }} key={selectedLang} >
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: isArabic ? 0 : 'auto',
          left: isArabic ? 'auto' : 0,
          backgroundColor: '#7F28F8',
        }}
        breakpoint='md'
      >
        <div className="demo-logo-vertical">
          <img className='' alt='company-alt' src={ selectedLang==='ar' ? arLogo : enLogo} />
        </div>
        <Menu
            theme='transparent'
            style={{ color: 'white', marginTop: '40px', fontSize: 15 }}
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            onClick={handleMenuClick}

            defaultSelectedKeys={['1']}
>
          {items.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              style={{ backgroundColor: hoveredItem === item.key ? '#9E41FF' : 'transparent' }}
              onMouseEnter={() => handleMouseEnter(item.key)}
              onMouseLeave={handleMouseLeave}
            >
              {item.path ? (
                <Link to={item.path}>{item.label}</Link>
              ) : (
                <>{item.label}</>
              )}
            </Menu.Item>
          ))}
        </Menu>
        <div className="logoutbtn flex gap-3" onClick={handleLogout}>
          <LogoutOutlined style={{ display: collapsed ? 'block' : 'block' }} />
          <h2 className={`mb-0 ${collapsed ? 'hidden' : ''}`}>{t('navbar.logout')}</h2>
        </div>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: isArabic ? 'auto' : collapsed ? 80 : 200,
          marginRight: isArabic ? (collapsed ? 80 : 200) : 'auto', }}>
        {shouldRenderHeader() && (
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              height: "55px",
              background: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <LanguageSelector  setSelectedLang={setSelectedLang} />

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={toggleCollapsed}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Header>
        )}
        <Content style={{ margin: '24px 16px 0', backgroundColor: 'white', overflow: 'initial' }}>
          <div className="site-layout-background" style={{ padding: 10, minHeight: 360, backgroundColor: 'white' }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
