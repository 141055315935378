import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import config from "../config";
import { Spin } from "antd";
import 'antd/dist/antd'

const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { token } = useAuth();









  useEffect(() => {
    const checkUserAuth = async () => {
      if (localStorage.getItem("token")) {
        const token = localStorage.getItem("token");
  
        try {
          const response = await fetch(`${config.apiUrl}user/CheckUserAuth`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
      
          if (response.ok) {
            const data = await response.json();
            localStorage.setItem('UserObject', JSON.stringify(data));
            localStorage.setItem('username',data.username)
            setIsAuthenticated(true);
            if (data.is_invited_member){
              localStorage.setItem('invited',true)
              const teamResponse = await fetch(`${config.apiUrl}team/permission/`, {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              });
  
      
              if (teamResponse.ok) {
                const teamData = await teamResponse.json();
                if(teamData.permission=="Super User"){
                  localStorage.setItem('superUser',true)
                }
                else{
                  localStorage.setItem('superUser',false)
  
                }
              }
            }
            else{
              localStorage.removeItem('invited')
            }
     
          } else {
            console.error('Authorization failed:', response.status, response.statusText);
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Error:", error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkUserAuth();
  }, []);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" />;
  }


  return <Outlet />;
};

export default PrivateRoute;
